import {
    AbiRegistry,
    Address,
    SmartContract,
} from '@multiversx/sdk-core/out';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import lsAbiJson from 'assets/abi/vesta-x-liquid-staking.abi.json';
import {
    ELROND_ACCESS_TIMEOUT,
    ELROND_GATEWAY_ADDRESS,
    LIQUID_STAKING_SMART_CONTRACT_ADDRESS,
} from 'config';

//
export const elrondProvider = new ProxyNetworkProvider(ELROND_GATEWAY_ADDRESS, { timeout: ELROND_ACCESS_TIMEOUT });

//
function createSmartContract(abiJson: any, scAddress?: string): SmartContract {
    const abiRegistry = AbiRegistry.create(abiJson);
    return new SmartContract({
        address: scAddress ? new Address(scAddress) : undefined,
        abi: abiRegistry,
    });
}

export const liquidStakingSmartContract = createSmartContract(lsAbiJson, LIQUID_STAKING_SMART_CONTRACT_ADDRESS);

// if tx is not a Transaction instance but a plain object, "transformAndSignTransactions" is applied to the object so you don't need to call "refreshAccount" for sync nonce
export async function elrondDappSendTransactions(txs: any, txName: string) {
    const { sessionId, error } = await sendTransactions({
        transactions: txs,
        transactionsDisplayInfo: {
            processingMessage: `Processing ${txName} Request`,
            errorMessage: `Error occured during ${txName} Request`,
            successMessage: `${txName} Request Successful`,
        },
        redirectAfterSign: false,
    });

    return { sessionId, error };
}
