import axios from 'axios';
import { ELROND_API_ADDRESS } from 'config';
import {
    ElrondStatsType,
    TokenBalanceType,
    TokenInfoType,
} from 'z/types';
import { convertSecondsToPrintDateTime } from 'z/utils';

export const axoisConfig = {
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Content-Type': 'application/json;charset=UTF-8',
    },
};

export async function getTokenBalanceFromApi(address: string, token: string): Promise<TokenBalanceType> {
    const configUrl = `${ELROND_API_ADDRESS}/accounts/${address}/tokens/${token}`;
    
    try {
        const { data } = await axios.get<TokenBalanceType>(configUrl, axoisConfig);
        return data;
    } catch (err) {
        console.error(err);
    }
    
    return {
        balance: '0',
    };
}

export async function getEgldPriceFromApi(): Promise<number | null> {
    const configUrl = 'https://api.multiversx.com/tokens/WEGLD-bd4d79';
    
    try {
        const { data } = await axios.get<TokenInfoType>(configUrl, axoisConfig);
        return data.price === undefined ? null : data.price;
    } catch (err) {
        console.error(err);
    }
    
    return null;
}

export async function getElrondStatsFromApi(): Promise<ElrondStatsType | null> {
    const configUrl = `${ELROND_API_ADDRESS}/stats`;
    
    try {
        const { data } = await axios.get<ElrondStatsType>(configUrl, axoisConfig);
        
        if (data) {
            data.leftTime = convertSecondsToPrintDateTime((data.roundsPerEpoch - data.roundsPassed) * 6);
            data.passedTimePercentage = data.roundsPassed / data.roundsPerEpoch * 100;
        }
        
        return data;
    } catch (err) {
        console.error(err);
    }
    
    return null;
}