import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { ElrondStatsType } from 'z/types';

export interface NetstatsState {
    egldPrice: number | null,
    elrondStats: ElrondStatsType | null,
}

const initialState: NetstatsState = {
    egldPrice: null,
    elrondStats: null,
};

export const netstatsSlice = createSlice({
    name: 'netstats',
    initialState,
    reducers: {
        setEgldPrice: (state, action: PayloadAction<number | null>) => {
            state.egldPrice = action.payload;
        },
        setElrondStats: (state, action: PayloadAction<ElrondStatsType | null>) => {
            state.elrondStats = action.payload;
        },
    }
});

export const { setEgldPrice, setElrondStats } = netstatsSlice.actions;
export const selectNetstats = (state: RootState) => state.netstas;

export default netstatsSlice.reducer;