import React, { useEffect, useState }  from 'react';
import {
    useGetAccount,
    useGetLoginInfo,
    useGetPendingTransactions,
} from '@multiversx/sdk-dapp/hooks';
import imgAudited from 'assets/img/audited.png';
import egldLogo from 'assets/img/elrond.svg';
import imgArdaLogo from 'assets/img/logo-arda.png';
import imgBhnetwork from 'assets/img/logo-bhnetwork.svg';
import testi2bg from 'assets/img/testi2-bg.svg';
import vEgldLogo from 'assets/img/VEGLD.png';
import watermark2Bg from 'assets/img/watermark2-bg.png';
import { Faq } from 'components/Faq';
import {
    AUDIT_ARDA_LINK,
    AUDIT_BHNETWORK_LINK,
    VEGLD,
} from 'config';
import { selectLiquidStaking, selectNetstats } from 'reducers';
import { useAppSelector } from 'store';
import {
    // elrondGetUnstakingPacks,
    elrondStake,
    elrondUnstake,
    elrondWithdraw,
} from 'z/elrond';
import { UnstakingPackType } from 'z/types';
import {
    // convertBigNumberToInputString,
    convertBigNumberToLocalString,
    convertEsdtToWei,
    convertSecondsToDays,
    convertSecondsToLocalDateTime,
    convertWeiToEsdt,
    DEFAULT_DECIMALS,
    DELTA_AMOUNT,
    ERROR_CONNECT_WALLET,
    ERROR_INVALID_NUMBER,
    ERROR_NOT_ENOUGH_BALANCE,
    ERROR_NO_ENOUGH_FUNDS_IN_POOL,
    ERROR_NO_FUNDS_FOR_WITHDRAWL,
    isPositiveOrZeroBigNumber,
    parseBigNumber,
    toastError,
    ZERO_STRING,
} from 'z/utils';

const tabs = ['Stake', 'Unstake'];

export const Home = () => {
    const { isLoggedIn } = useGetLoginInfo();
    const { address, balance: egldBalance } = useGetAccount();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const netstats = useAppSelector(selectNetstats);
    const liquidStaking = useAppSelector(selectLiquidStaking);
    const vegldPrice = liquidStaking.liquidStakingSettings ? convertWeiToEsdt(liquidStaking.liquidStakingSettings.vegld_price) : null;

    const [tab, setTab] = useState(0);

    const [stakeEgldAmount, setStakeEgldAmount] = useState<string>(ZERO_STRING);
    const [stakeEgldAmountError, setStakeEgldAmountError] = useState<string>('');
    const [unstakeVegldAmount, setUnstakeVegldAmount] = useState<string>(ZERO_STRING);
    const [unstakeVegldAmountError, setUnstakeVegldAmountError] = useState<string>('');

    function onStakeEgldAmountChange(value: string) {
        let error = '';
        
        if (!isPositiveOrZeroBigNumber(value)) {
            error = ERROR_INVALID_NUMBER;
        } else if (convertEsdtToWei(parseBigNumber(value).plus(DELTA_AMOUNT)).comparedTo(egldBalance) > 0) {
            error = ERROR_NOT_ENOUGH_BALANCE;
        }

        setStakeEgldAmountError(error);
        setStakeEgldAmount(value);
    }

    function onMaxStakeEgldAmount() {
        let error = '';
        if (!isLoggedIn) {
            error = ERROR_CONNECT_WALLET;
        }

        if (error) {
            toastError(error);
            return;
        }

        const value = convertWeiToEsdt(egldBalance).minus(DELTA_AMOUNT);
        onStakeEgldAmountChange(value.comparedTo(0) < 0 ? ZERO_STRING : value.toFixed());
    }
    
    async function onStake() {
        let error = '';
        if (!isLoggedIn) {
            error = ERROR_CONNECT_WALLET;
        } else if (!isPositiveOrZeroBigNumber(stakeEgldAmount)) {
            error = ERROR_INVALID_NUMBER;
        } else if (convertEsdtToWei(parseBigNumber(stakeEgldAmount).plus(DELTA_AMOUNT)).comparedTo(egldBalance) > 0) {
            error = ERROR_NOT_ENOUGH_BALANCE;
        }

        if (error) {
            toastError(error);
            return;
        }

        await elrondStake(convertEsdtToWei(stakeEgldAmount).toFixed(0));
    }

    //
    function onUnstakeVegldAmountChange(value: string) {
        let error = '';
        
        if (!isPositiveOrZeroBigNumber(value)) {
            error = ERROR_INVALID_NUMBER;
        } else if (!liquidStaking.vegldBalance || convertEsdtToWei(value).comparedTo(liquidStaking.vegldBalance) > 0) {
            error = ERROR_NOT_ENOUGH_BALANCE;
        }

        setUnstakeVegldAmountError(error);
        setUnstakeVegldAmount(value);
    }

    function onMaxUnstakeVegldAmount() {
        let error = '';
        if (!isLoggedIn) {
            error = ERROR_CONNECT_WALLET;
        }

        if (error) {
            toastError(error);
            return;
        }

        onUnstakeVegldAmountChange(convertWeiToEsdt(liquidStaking.vegldBalance, DEFAULT_DECIMALS, DEFAULT_DECIMALS).toFixed());
    }
    
    async function onUnstake() {
        let error = '';
        if (!isLoggedIn) {
            error = ERROR_CONNECT_WALLET;
        } else if (!isPositiveOrZeroBigNumber(stakeEgldAmount)) {
            error = ERROR_INVALID_NUMBER;
        } else if (!liquidStaking.vegldBalance || convertEsdtToWei(unstakeVegldAmount).comparedTo(liquidStaking.vegldBalance) > 0) {
            error = ERROR_NOT_ENOUGH_BALANCE;
        }

        if (error) {
            toastError(error);
            return;
        }

        await elrondUnstake(convertEsdtToWei(unstakeVegldAmount).toFixed(0));
    }

    const onWithdraw = async () => {
        let error = '';
        if (!isLoggedIn) {
            error = ERROR_CONNECT_WALLET;
        } else if (liquidStaking.withdrawableAmount <= 0) {
            error = ERROR_NO_FUNDS_FOR_WITHDRAWL;
        } else if (!liquidStaking.vegldBalance || convertEsdtToWei(unstakeVegldAmount).comparedTo(liquidStaking.vegldBalance) > 0) {
            error = ERROR_NOT_ENOUGH_BALANCE;
        } else if (liquidStaking.liquidStakingSettings && convertEsdtToWei(liquidStaking.withdrawableAmount).comparedTo(liquidStaking.liquidStakingSettings.unbonded_egld_amount) > 0) {
            error = ERROR_NO_ENOUGH_FUNDS_IN_POOL;
        }

        if (error) {
            toastError(error);
            return;
        }

        await elrondWithdraw();
    };

    function resetInputs() {
        setStakeEgldAmount(ZERO_STRING);
        setUnstakeVegldAmount(ZERO_STRING);
        setStakeEgldAmountError('');
        setUnstakeVegldAmountError('');
    }

    useEffect(() => {
        if (hasPendingTransactions) return;
        resetInputs();
    }, [address, hasPendingTransactions]);

    const handleRedirectDex = () => {
        window.location.href = "https://vestadex.com/";
    };

    return (
        <>
            <div className='container' style={{ marginTop: '50px' }}>
                <div className='row justify-content-center staking-individual-info' style={{ rowGap: '20px' }}>
                <div className='col-sm-12 col-lg-3 col-md-6'>
                    <div className='testimonial-single2 hover-border2 fadeInDown'>
                        <img
                            src={testi2bg}
                            alt="image"
                            className="testi2-bg"
                        />
                        <div className='d-flex flex-column'>
                            <span style={{ letterSpacing: '0.1rem' }}>{VEGLD.ticker}/EGLD Price</span>
                            <span className='mt-3 mb-3' style={{ fontWeight: '600', fontSize: '1.35rem' }}>{`${liquidStaking.liquidStakingSettings ? convertBigNumberToLocalString(convertWeiToEsdt(liquidStaking.liquidStakingSettings.vegld_price, DEFAULT_DECIMALS, DEFAULT_DECIMALS), 6) : '?'} EGLD`}</span>
                            <span>{`≈ $${vegldPrice && netstats.egldPrice ? convertBigNumberToLocalString(vegldPrice.multipliedBy(netstats.egldPrice)) : '?'}`}</span>
                        </div>
                    </div>
                </div>

                <div className='col-sm-12 col-lg-3 col-md-6'>
                    <div className='testimonial-single2 hover-border2 fadeInDown'>
                        <img
                            src={testi2bg}
                            alt="image"
                            className="testi2-bg"
                        />
                        <div className='d-flex flex-column'>
                            <span style={{ letterSpacing: '0.1rem' }}>Total Staked</span>
                            <span className='mt-3 mb-3' style={{ fontWeight: '600', fontSize: '1.35rem' }}>{liquidStaking.liquidStakingSettings ? convertBigNumberToLocalString(convertWeiToEsdt(liquidStaking.liquidStakingSettings.pool_egld_amount)) : '?'} EGLD</span>
                            <span>{liquidStaking.liquidStakingSettings && netstats.egldPrice ? '$' + convertBigNumberToLocalString(convertWeiToEsdt(liquidStaking.liquidStakingSettings.pool_egld_amount).multipliedBy(netstats.egldPrice)) : '?'}</span>
                        </div>
                    </div>
                </div>

                <div className='col-sm-12 col-lg-3 col-md-6'>
                    <div className='testimonial-single2 hover-border2 fadeInDown'>
                        <img
                            src={testi2bg}
                            alt="image"
                            className="testi2-bg"
                        />
                        <div className='d-flex flex-column'>
                            <span style={{ letterSpacing: '0.1rem' }}>Epoch</span>
                            <span className='mt-3 mb-3' style={{ fontWeight: '600', fontSize: '1.35rem' }}>{netstats.elrondStats ? convertBigNumberToLocalString(netstats.elrondStats.roundsPerEpoch / 600) : '?'} hours</span>
                            <span>
                                Next Epoch: {netstats.elrondStats ? (
                                    <>
                                    <span style={{ fontWeight: '700' }}>{netstats.elrondStats.leftTime.hours}</span><span>h</span>&nbsp;&nbsp;
                                    <span style={{ fontWeight: '700' }}>{netstats.elrondStats.leftTime.minutes}</span><span>m</span>&nbsp;&nbsp;
                                    <span style={{ fontWeight: '700' }}>{netstats.elrondStats.leftTime.seconds}</span><span>s</span>&nbsp;
                                    </>
                                ) : '?'}
                            </span>
                        </div>
                    </div>
                </div>

                <div className='col-sm-12 col-lg-3 col-md-6'>
                    <div className='testimonial-single2 hover-border2 fadeInDown'>
                        <img
                            src={testi2bg}
                            alt="image"
                            className="testi2-bg"
                        />
                        <div className='d-flex flex-column'>
                            <span style={{ letterSpacing: '0.1rem' }}>APY</span>
                            <span className='mt-3 mb-3' style={{ fontWeight: '600', fontSize: '1.35rem' }}>9.67%</span>
                            <span>Daily Auto-Compounding</span>
                        </div>
                    </div>
                </div>
                </div>

                <div className='staking-all-in-one-info testimonial-single2 hover-border2 fadeInDown'>
                    <div className='d-flex justify-content-between'>
                        <span>{VEGLD.ticker}/EGLD Price</span>
                        <span style={{ fontWeight: '600', fontSize: '1.125rem' }}>{vegldPrice ? convertBigNumberToLocalString(vegldPrice, 6) : '?'} EGLD</span>
                    </div>

                    <div className='d-flex justify-content-between'>
                        <span >Total EGLD Staked</span>
                        <span style={{ fontWeight: '600', fontSize: '1.125rem' }}>{liquidStaking.liquidStakingSettings ? convertBigNumberToLocalString(convertWeiToEsdt(liquidStaking.liquidStakingSettings.pool_egld_amount)) : '?'} EGLD</span>
                    </div>

                    <div className='d-flex justify-content-between'>
                        <span >Epoch</span>
                        <span style={{ fontWeight: '600', fontSize: '1.125rem' }}>{netstats.elrondStats ? convertBigNumberToLocalString(netstats.elrondStats.roundsPerEpoch / 600) : '?'} hours</span>
                    </div>

                    <div className='d-flex justify-content-between'>
                        <span>APR</span>
                        <span style={{ fontWeight: '600', fontSize: '1.125rem' }}>6.85%</span>
                    </div>
                </div>
            </div>

            <div className='service-section'>
                <img src={watermark2Bg} alt="image" className="watermark2-bg" />
                <div>
                    <div className='d-flex justify-content-center' style={{ marginTop: '45px' }}>
                        <div className='text-center d-flex flex-column'>
                        <span style={{ fontSize: '3.25rem', letterSpacing: '0.325rem', fontWeight: '700' }}>Stake EGLD</span>
                        <span style={{ fontSize: '1.25rem', color: '#00caeb' }}>Stake EGLD and use {VEGLD.ticker} while earning rewards from Daily Auto-Compounding</span>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center' style={{ marginTop: '30px' }}>
                        <div className='main-tab'>
                        <div className={`${tab === 0 ? 'main-tab-back-tab-0' : 'main-tab-back-tab-1'}`} />
                            {tabs.map((row, index) => (
                                <span
                                    className={`main-tab-individual ${tab === index ? 'text-white' : 'text-black'}`}
                                    key={row}
                                    onClick={() => {
                                        if (hasPendingTransactions) return;
                                        setTab(index);
                                    }}
                                >
                                    {row}
                                </span>
                            ))}
                        </div>
                    </div>
                    {tab === 0 ? (<>
                        <div className='d-flex flex-fill justify-content-center container'>
                            <div className='row w-100'>
                                <div className='col-sm-12 col-md-8 col-lg-5 mx-auto mt-2 mb-5'>
                                    <div className='mt-4 p-4 info-item'>
                                        <div className='section-title2'>
                                            <span>Stake</span>
                                        </div>
                                        <div className="form-group">
                                            <div className='mt-2 A-form' >
                                            <div className='d-flex justify-content-between gap-3'>
                                                <div className='A-logo'>
                                                    <img src={egldLogo} alt="vesta" />
                                                    <span>EGLD</span>
                                                </div>
                                                <input
                                                    // type="number"
                                                    className="A-input"
                                                    value={stakeEgldAmount}
                                                    onChange={e => onStakeEgldAmountChange(e.target.value)} placeholder="0.0"
                                                />
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <span style={{ fontSize: '0.85rem' }}>
                                                    Balance: {isLoggedIn && `${convertBigNumberToLocalString(convertWeiToEsdt(egldBalance))} EGLD`}
                                                </span>
                                                <button
                                                    className='max-but'
                                                    disabled={hasPendingTransactions}
                                                    onClick={onMaxStakeEgldAmount}
                                                >
                                                    MAX
                                                </button>
                                            </div>
                                            </div>
                                            {stakeEgldAmountError && (<small className="form-text text-danger">{stakeEgldAmountError}</small>)}
                                        </div>
                                        <div className="form-group mt-4">
                                            <span style={{ marginLeft: '20px', fontWeight: '600' }}>You will receive</span>
                                            <div className='B-form' >
                                            <div className='d-flex justify-content-between gap-3'>
                                                <div className='B-logo'>
                                                    <img src={vEgldLogo} alt="vesta" />
                                                    <span>vEGLD</span>
                                                </div>
                                                <input
                                                    className="B-input"
                                                    disabled
                                                    value={stakeEgldAmount && liquidStaking.liquidStakingSettings ? convertBigNumberToLocalString(convertEsdtToWei(stakeEgldAmount).div(liquidStaking.liquidStakingSettings.vegld_price)) : ZERO_STRING}
                                                />
                                            </div>
                                            <span style={{ fontSize: '0.85rem' }}>
                                                Balance: {isLoggedIn && liquidStaking.liquidStakingSettings && (<>
                                                    {`${convertBigNumberToLocalString(convertWeiToEsdt(liquidStaking.vegldBalance))} ${VEGLD.ticker} (≈ `}
                                                    <strong>{convertBigNumberToLocalString(convertWeiToEsdt(liquidStaking.vegldBalance).times(convertWeiToEsdt(liquidStaking.liquidStakingSettings.vegld_price)))}</strong>
                                                    {' EGLD)'}
                                                </>)}
                                            </span>
                                            </div>
                                            {/* <small className="form-text red">We&apos;ll never share your email with anyone else.</small> */}
                                        </div>
                                        <div className='mt-2 p-2'>
                                            <div className="d-flex justify-content-between">
                                                <span>Exchange Rate:</span>
                                                <span>
                                                    {
                                                        liquidStaking.liquidStakingSettings && (<>
                                                            <strong>1</strong> {VEGLD.ticker} ≈ <strong>{convertBigNumberToLocalString(convertWeiToEsdt(liquidStaking.liquidStakingSettings.vegld_price, DEFAULT_DECIMALS, DEFAULT_DECIMALS), 6)}</strong> EGLD
                                                        </>)
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className='mt-4 d-flex justify-content-center'>
                                            <button
                                                className="eg-btn btn--primary2 capsule btn--lg ls-action-button"
                                                disabled={hasPendingTransactions || !!stakeEgldAmountError}
                                                onClick={onStake}
                                            >
                                                Stake
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>) : <>
                        <div className='d-flex flex-fill justify-content-center container'>
                            <div className='row w-100'>
                                <div className='col-12 col-md-8 col-lg-5 mx-auto mt-2 mb-5'>
                                <div className='mt-4 p-4 info-item'>
                                    <div className='section-title2'>
                                        <span>Unstake</span>
                                    </div>
                                    <div className="form-group">
                                        <div className='mt-2 A-form' >
                                            <div className='d-flex justify-content-between gap-3'>
                                                <div className='A-logo'>
                                                    <img src={vEgldLogo} alt="vesta" />
                                                    <span>vEGLD</span>
                                                </div>
                                                <input
                                                    // type="number"
                                                    className="A-input"
                                                    value={unstakeVegldAmount}
                                                    onChange={e => onUnstakeVegldAmountChange(e.target.value)}
                                                />
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <span style={{ fontSize: '0.85rem' }}>
                                                    Balance: {isLoggedIn && `${convertBigNumberToLocalString(convertWeiToEsdt(liquidStaking.vegldBalance))} ${VEGLD.ticker}`}
                                                </span>
                                                <button
                                                    className='max-but'
                                                    disabled={hasPendingTransactions}
                                                    onClick={onMaxUnstakeVegldAmount}
                                                >
                                                    MAX
                                                </button>
                                            </div>
                                        </div>

                                        {unstakeVegldAmountError && (<small className="form-text text-danger">{unstakeVegldAmountError}</small>)}
                                    </div>
                                    <div className="form-group mt-3">
                                        <span style={{ marginLeft: '20px', fontWeight: '600' }}>You will receive</span>
                                        <div className='B-form' >
                                            <div className='d-flex justify-content-between gap-3'>
                                                <div className='B-logo'>
                                                    <img src={egldLogo} alt="vesta" />
                                                    <span>EGLD</span>
                                                </div>
                                                <input
                                                    // type="number"
                                                    className="B-input"
                                                    disabled
                                                    value={unstakeVegldAmount && liquidStaking.liquidStakingSettings ? convertBigNumberToLocalString(convertWeiToEsdt(parseBigNumber(unstakeVegldAmount).multipliedBy(liquidStaking.liquidStakingSettings.vegld_price))) : ZERO_STRING}
                                                />
                                            </div>
                                            <span style={{ fontSize: '0.85rem' }}>
                                                Balance: {isLoggedIn && `${convertBigNumberToLocalString(convertWeiToEsdt(egldBalance))} EGLD`}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='mt-2 p-2'>
                                    <div className='d-flex justify-content-between'>
                                        <span>Exchange Rate:</span>
                                        <span>
                                            {
                                                liquidStaking.liquidStakingSettings && (<>
                                                    <strong>1</strong> {VEGLD.ticker} ≈ <strong>{convertBigNumberToLocalString(convertWeiToEsdt(liquidStaking.liquidStakingSettings.vegld_price, DEFAULT_DECIMALS, DEFAULT_DECIMALS), 6)}</strong> EGLD
                                                </>)
                                            }
                                        </span>
                                    </div>
                                    <div className='d-flex justify-content-between mt-2'>
                                        <span>Unstaking Period:</span>
                                        <span>{liquidStaking.liquidStakingSettings ? convertBigNumberToLocalString(convertSecondsToDays(liquidStaking.liquidStakingSettings.unbonding_period)) : '-'} Days</span>
                                    </div>
                                    {/* <div className='mt-2 d-flex justify-content-between'>
                                        <span>Balance:</span>
                                        <span style={{textAlign: 'right'}}>
                                        <strong>{convertWeiToEsdtString(liquidStaking.vegldBalance)}</strong> {VEGLD.ticker}
                                        <br />
                                        (≈ <strong>{liquidStaking.vegldBalance && liquidStaking.liquidStakingSettings && convertWeiToEsdt(liquidStaking.vegldBalance).times(convertWeiToEsdt(liquidStaking.liquidStakingSettings.vegld_price)).toFixed(4)}</strong> EGLD)
                                        </span>
                                    </div> */}
                                    </div>
                                    <div className='mt-4 d-flex justify-content-center'>
                                        <button
                                            className="eg-btn btn--primary2 capsule btn--lg ls-action-button"
                                            disabled={hasPendingTransactions || !!unstakeVegldAmountError}
                                            onClick={onUnstake}
                                        >
                                            Unstake
                                        </button>
                                    </div>
                                </div>

                                <div className='mt-4 p-4 info-item'>
                                    <div>
                                    <h4 className='text-center font-weight-bold'>Unstaking Packs</h4>
                                    </div>
                                    <div className='mt-4'>
                                    {
                                        liquidStaking.unstakingPacks.length > 0 ? liquidStaking.unstakingPacks.map((item: UnstakingPackType, index: number) => (
                                        <div className='d-flex justify-content-between gap-2' key={`u-p-${index}`}>
                                            <span>{convertSecondsToLocalDateTime(item.timestamp)}</span>
                                            <span><strong>{convertBigNumberToLocalString(convertWeiToEsdt(item.amount))}</strong> EGLD</span>
                                            <span>{item.withdrawable ? 'Withdrawable' : item.leftTimeString}</span>
                                        </div>
                                        )) : (
                                        <div className=''>
                                            <div className='text-center' style={{ color: 'gray' }}>- No Unstaking Packs -</div>
                                        </div>
                                        )
                                    }
                                    </div>
                                </div>

                                <div className='mt-4 p-4 info-item'>
                                    <div>
                                        <h4 className='text-center font-weight-bold'>Withdraw Pool</h4>
                                    </div>
                                    <div className='mt-4'>
                                        <div className='d-flex justify-content-between'>
                                            <span>My Withdraw:</span>
                                            <span><strong>{convertBigNumberToLocalString(liquidStaking.withdrawableAmount)}</strong> EGLD</span>
                                        </div>
                                    </div>
                                    <div className='mt-4 d-flex justify-content-center'>
                                        <button
                                            className="eg-btn btn--primary2 capsule btn--lg ls-action-button"
                                            disabled={hasPendingTransactions}
                                            onClick={onWithdraw}
                                        >
                                            Withdraw
                                        </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </>}
                </div>
            </div>

            <div className='dex-button-container'>
                <button className='btn-redirect-dex' onClick={handleRedirectDex}>
                    <span>Visit {' '}<b style={{ color: '#00caeb' }}>VestaX DEX</b></span>
                </button>
            </div>

            <div className='mt-5'>
                <div>
                    <img
                        src={imgAudited}
                        alt="audited"
                        className="img-audited"
                    />
                </div>
                <div className='auditor-container mt-3'>
                    <a href={AUDIT_ARDA_LINK} target="_blank" rel="noreferrer">
                        <img
                            src={imgArdaLogo}
                            alt="auditor"
                            className="img-auditor"
                        />
                    </a>
                    <a href={AUDIT_BHNETWORK_LINK} target="_blank" rel="noreferrer">
                        <img
                            src={imgBhnetwork}
                            alt="auditor"
                            className="img-auditor"
                        />
                    </a>
                </div>
            </div>

            <Faq />
        </>
    );
};