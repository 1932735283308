import React from 'react';
import watermark1Bg from '../../assets/img/watermark1-bg.png';

export const Faq = () => {
    return (
        <div className="faq-section" style={{ marginTop: '6rem' }}>
            <img
                src={watermark1Bg}
                className="watermark1-bg"
                alt="image"
            />
            <div className="container">
                <div className="row g-4 justify-content-center">
                    <div className="col-sm-12 col-md-8 col-lg-5">
                        <div className="faq-wrap wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".2s">
                            <div className='section-title2'>
                                <span>Faq</span>
                            </div>
                            <div className="faq-item hover-btn"><span />
                                <h5 id="heading10" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-controls="collapse10">
                                    What are the strong points of vEGLD?
                                </h5>
                                <div id="collapse10" className="accordion-collapse collapse" aria-labelledby="heading10">
                                    <div className="faq-body">
                                        <ul>
                                            <li>
                                                The main advantage is compound auto staking: your yearly APR is indexed every 24h in the price of vEGLD.
                                            </li>
                                            <li>
                                                While your EGLD is locked at staking you receive proportional liquidity in vEGLD so you can invest, reinvest, mint, trade, farm, and stake.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="faq-item hover-btn"><span />
                                <h5 id="heading11" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-controls="collapse11">
                                    What are the Pros of Liquid Staking compared to Traditional Staking?
                                </h5>
                                <div id="collapse11" className="accordion-collapse collapse" aria-labelledby="heading11">
                                    <div className="faq-body">
                                        <ul>
                                            <li>
                                                It boosts greatly the whole economy of the blockchain, by releasing the locked liquidity from the staking.
                                            </li>
                                            <li>
                                                Allows investors to seize instant opportunities while having liquidity to invest, as opposed to missing opportunities because EGLD is locked.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="faq-item hover-btn"><span />
                                <h5 id="heading12" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse12" aria-controls="collapse12">
                                    What are the cons of Liquid Staking vs Traditional Staking?
                                </h5>
                                <div id="collapse12" className="accordion-collapse collapse" aria-labelledby="heading12">
                                    <div className="faq-body">
                                        <ul>
                                            <li>
                                                The vEGLD is the key to unlocking your staked EGLD, including the APR. If you lose your vEGLD someone else who gets your vEGLD will unlock the staked EGLD.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="faq-item hover-btn"><span />
                                <h5 id="heading13" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse13" aria-controls="collapse13">
                                    If Liquid EGLD has a fee from the staked APR, how can be that be appealing for people to use it?
                                </h5>
                                <div id="collapse13" className="accordion-collapse collapse" aria-labelledby="heading13">
                                    <div className="faq-body">
                                        <ul>
                                            <li>
                                                Currently, our Liquid EGLD protocol has a small 5% fee from yearly APR, which means the user receives 95% of his APR.
                                            </li>
                                            <li>
                                                In exchange, our protocol offers access to liquidity while simultaneously staking the EGLD.
                                            </li>
                                            <li>
                                                Extended utility similar to the EGLD, as you can do LP pairs, farm, mint, stake, invest, and reinvest with vEGLD.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="faq-item hover-btn"><span />
                                <h5 id="heading14" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse14" aria-controls="collapse14">
                                    How do we generate profit for Vesta X holders?
                                </h5>
                                <div id="collapse14" className="accordion-collapse collapse" aria-labelledby="heading14">
                                    <div className="faq-body">
                                        <ul>
                                            <li>
                                                50% of all the fees received by Vesta X are to be shared with Gold, Silver and Bronze Vesta X holders.
                                            </li>
                                            <li>
                                                This revenue is applied to all kind of fees, including the vEGLD fee from the APR, all the Vesta DEX fees, all the lending platform fees.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};