import React from 'react';
import { Toaster } from 'react-hot-toast';
import { Footer } from './Footer';
import { Navbar } from './Navbar';

export const Layout = ({ children }: { children: React.ReactNode }) => {
    return (
        <div className='d-flex flex-column flex-fill wrapper valar-main' style={{ background: '#fff' }}>
            <Navbar />
            <main className='d-flex flex-column flex-grow-1'>
                {children}
            </main>
            <Toaster />
            <Footer />
        </div>
    );
};
