import React, { useEffect } from 'react';
import { useGetAccount, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { logout } from '@multiversx/sdk-dapp/utils';
import { Navbar as BsNavbar, NavItem, Nav } from 'react-bootstrap';
import { IoWallet, IoClose } from 'react-icons/io5';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import vestaXLogo from 'assets/img/logo.png';
import { Unlock } from 'pages';
import { routeNames } from 'routes';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

export const Navbar = () => {
    const isLoggedIn = useGetIsLoggedIn();
    const { address } = useGetAccount();

    const handleLogout = () => {
        logout();
    };

    const [modalIsOpen, setIsOpen] = React.useState(false);

    useEffect(() => {
        if (isLoggedIn) {
            closeModal();
        }
    }, [isLoggedIn]);

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <>
            <BsNavbar className='bg-white border-bottom vesta-navbar py-2'>
                <div className='container-fluid container'>
                    <Link
                        className='d-flex align-items-center navbar-brand mr-0'
                        to={routeNames.home}
                    >
                        <img className='vesta-logo' src={vestaXLogo} />
                    </Link>

                    <Nav className='ml-auto'>
                        {isLoggedIn ? (
                            <NavItem>
                                <button
                                    className='eg-btn btn--primary2 capsule btn--md header_connect_but'
                                    style={{ padding: '6px 12px' }}
                                    onClick={handleLogout}
                                >
                                    <div className="wallet_connect_icon">
                                        <IoWallet />
                                    </div>
                                    <span style={{ fontSize: '.9rem' }}>{address.substring(0, 6) + '...' + address.slice(-6)}</span>
                                </button>
                            </NavItem>
                        ) : (
                            <NavItem>
                                <button
                                    className="eg-btn btn--primary2 capsule btn--md header_connect_but"
                                    style={{ padding: '6px 12px' }}
                                    onClick={() => setIsOpen(true)}
                                >
                                    <div className="wallet_connect_icon">
                                        <IoWallet />
                                    </div>
                                    <span style={{ fontSize: '1rem' }}>Connect</span>
                                </button>
                            </NavItem>
                        )}
                    </Nav>
                </div>
            </BsNavbar>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className='connection-modal'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <span style={{ color: '#F1DC46', marginLeft: '18px', fontSize: '1.3rem' }}>Connect to a wallet</span>

                        <div className='modal-close-but' onClick={closeModal}>
                            <IoClose />
                        </div>
                    </div>

                    <Unlock />
                </div>
            </Modal>
        </>
    );
};
