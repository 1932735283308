import React from 'react';
import {
    FaTwitter,
    FaDiscord,
    FaYoutube,
    FaTelegram,
    FaLink,
} from 'react-icons/fa';
import vestaSnakeImg from 'assets/img/vesta_snake.png';
import {
    DEMI_DISCORD_LINK,
    DEMI_TELEGRAM_LINK,
    DEMI_TWITTER_LINK,
    DEMI_WEBSITE_LINK,
    DEMI_YOUTUBE_LINK,
    VESTA_WEBSITE_LINK,
} from 'config';

export const Footer = () => {
    return (
        <div className='footer'>
            <div className='container text-center' style={{ marginTop: '40px', marginBottom: '20px' }}>
                <div className='mt-3'>
                    <img className='footer-vesta-snake' src={vestaSnakeImg} />
                </div>

                <div className='d-flex justify-content-center'>
                    <div className='d-flex gap-4'>
                        <a className='social-box' href={DEMI_TWITTER_LINK} target="_blank" rel="noreferrer">
                            <FaTwitter />
                        </a>
                        <a className='social-box' href={DEMI_YOUTUBE_LINK} target="_blank" rel="noreferrer">
                            <FaYoutube />
                        </a>
                        <a className='social-box' href={DEMI_DISCORD_LINK} target="_blank" rel="noreferrer">
                            <FaDiscord />
                        </a>
                        <a className='social-box' href={DEMI_TELEGRAM_LINK} target="_blank" rel="noreferrer">
                            <FaTelegram />
                        </a>
                        <a className='social-box' href={DEMI_WEBSITE_LINK} target="_blank" rel="noreferrer">
                            <FaLink />
                        </a>
                        <a className='social-box' href={VESTA_WEBSITE_LINK} target="_blank" rel="noreferrer">
                            <FaLink />
                        </a>
                    </div>
                </div>

                <div className='' style={{ marginTop: '30px' }}>
                    <p>
                        Copyright 2022 VestaX Finance &nbsp;|&nbsp; Powered By {' '}
                        <a href="https://demiourgos.holdings" className="egns-lab" target="_blank" rel="noreferrer">
                            Demiourgos Coding Division
                        </a>
                        {' & '}
                        <a href="https://synchronic.software" className="egns-lab" target="_blank" rel="noreferrer">
                            Synchronic Software
                        </a>
                    </p>
                </div>
            </div>
        </div >
    );
};
