import {
    Address,
    AddressValue,
    ArgSerializer,
    BigUIntValue,
    ResultsParser,
    StringValue,
    TypedValue,
} from "@multiversx/sdk-core/out";
import { FEE_DENOMINATOR, LIQUID_STAKING_SMART_CONTRACT_ADDRESS, STAKE_FUNCTION_NAME, STAKE_GAS_LIMIT, UNSTAKE_FUNCTION_NAME, UNSTAKE_GAS_LIMIT, VEGLD, WITHDRAW_FUNCTION_NAME, WITHDRAW_GAS_LIMIT } from "config";
import { LiquidStakingSettingsType, UnstakingPackType } from "z/types";
import { getCurrentUnixTimestamp, toastError } from "z/utils";
import { elrondDappSendTransactions, elrondProvider, liquidStakingSmartContract } from "./provider";

export async function elrondViewLiquidStakingSettings(): Promise<LiquidStakingSettingsType | undefined> {
    try {
        const interaction = liquidStakingSmartContract.methodsExplicit.viewLiquidStakingSettings();
        const query = interaction.check().buildQuery();
        const queryResponse = await elrondProvider.queryContract(query);
        const endpointDefinition = interaction.getEndpoint();
        const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(
            queryResponse,
            endpointDefinition,
        );
        
        if (!firstValue || !returnCode.isSuccess()) {
            toastError(returnMessage);
            return undefined;
        }
        
        const value = firstValue.valueOf();
        const decoded: LiquidStakingSettingsType = {
            vegld_identifier: value.vegld_identifier.toString(),
            treasury_wallet: value.treasury_wallet.toString(),
            fee: value.fee.div(FEE_DENOMINATOR).toNumber(),

            unbonding_period: value.unbonding_period.toNumber(),
            admins: value.admins.map((v: any) => v.toString()),
            user_action_allowed: value.user_action_allowed,
            management_action_allowed: value.management_action_allowed,
  
            pool_vegld_amount: value.pool_vegld_amount.toFixed(),
            pool_egld_amount: value.pool_egld_amount.toFixed(),
            prestaked_egld_amount: value.prestaked_egld_amount.toFixed(),
            preunstaked_egld_amount: value.preunstaked_egld_amount.toFixed(),
            unbonded_egld_amount: value.unbonded_egld_amount.toFixed(),
  
            vegld_price: value.vegld_price.toFixed(),
        };

        return decoded;
    } catch (err) {
        console.error(err);
    }

    return undefined;
}

export async function elrondGetUnstakingPacks(address: string, liquidStakingSettings: LiquidStakingSettingsType | undefined): Promise<UnstakingPackType[]> {
    try {
        const args: TypedValue[] = [
            new AddressValue(new Address(address)),
        ];
        const interaction = liquidStakingSmartContract.methodsExplicit.getUnstakingPacks(args);
        const query = interaction.check().buildQuery();
        const queryResponse = await elrondProvider.queryContract(query);
        const endpointDefinition = interaction.getEndpoint();
        const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(
            queryResponse,
            endpointDefinition,
        );
        
        if (!firstValue || !returnCode.isSuccess()) {
            toastError(returnMessage);
            return [];
        }
        
        const values = firstValue.valueOf();
        const decoded: UnstakingPackType[] = values.map((value: any) => {
            const timestamp = value.timestamp.toNumber();
            let withdrawable = false;
            let leftTimeString = '-';
            
            if (liquidStakingSettings) { // if unbondingPeriod is fetched
                if (getCurrentUnixTimestamp() >= timestamp + liquidStakingSettings.unbonding_period) {
                    withdrawable = true;
                } else {
                    const delta = (timestamp + liquidStakingSettings.unbonding_period) - getCurrentUnixTimestamp();
                    const days = Math.floor(delta / (3600 * 24)).toFixed(0);
                    const hours = Math.floor(delta / 3600) % 24;
                    const minutes = Math.floor(delta / 60) % 60;
                    leftTimeString = `${days}d ${hours}h ${minutes}m`;
                }
            }

            return {
                amount: value.amount.toFixed(),
                timestamp,
                withdrawable,
                leftTimeString,
            };
        });

        return decoded;
    } catch (err) {
        console.error(err);
    }

    return [];
}

export async function elrondStake(stakeEgldAmount: string) {
    const tx = {
        value: stakeEgldAmount,
        data: STAKE_FUNCTION_NAME,
        receiver: LIQUID_STAKING_SMART_CONTRACT_ADDRESS,
        gasLimit: STAKE_GAS_LIMIT
    };

    const txName = 'Stake';
    await elrondDappSendTransactions(tx, txName);
}

export async function elrondUnstake(unstakeVegldAmount: string) {
    const args: TypedValue[] = [
        new StringValue(VEGLD.identifier),
        new BigUIntValue(unstakeVegldAmount),
        new StringValue(UNSTAKE_FUNCTION_NAME),
    ];
    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `ESDTTransfer@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: LIQUID_STAKING_SMART_CONTRACT_ADDRESS,
        gasLimit: UNSTAKE_GAS_LIMIT,
    };

    const txName = 'Unstake';
    await elrondDappSendTransactions(tx, txName);
}

export async function elrondWithdraw() {
    const tx = {
        value: 0,
        data: WITHDRAW_FUNCTION_NAME,
        receiver: LIQUID_STAKING_SMART_CONTRACT_ADDRESS,
        gasLimit: WITHDRAW_GAS_LIMIT,
    };

    const txName = 'Withdraw';
    await elrondDappSendTransactions(tx, txName);
}
