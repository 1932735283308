import React from 'react';
import {
  TransactionsToastList,
  SignTransactionsModals,
  NotificationModal
} from '@multiversx/sdk-dapp/UI';
import { DappProvider } from '@multiversx/sdk-dapp/wrappers';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { Layout } from 'components';
import { ReduxProvider } from 'components/ReduxProvider';
import { ELROND_ACCESS_TIMEOUT, ELROND_ENVIRONMENT, walletConnectV2ProjectId } from 'config';
import { PageNotFound, Home } from 'pages';
import { routeNames } from 'routes';

export const App = () => {
    return (
        <Router>
            <DappProvider
                environment={ELROND_ENVIRONMENT}
                customNetworkConfig={{
                    name: 'customConfig',
                    apiTimeout: ELROND_ACCESS_TIMEOUT,
                    walletConnectV2ProjectId
                }}
            >
                <ReduxProvider>
                    <Layout>
                        <TransactionsToastList />
                        <NotificationModal />
                        <SignTransactionsModals className='custom-class-for-modals' />
                        <Routes>
                            <Route path={routeNames.home} element={<Home />} />
                            <Route path='*' element={<PageNotFound />} />
                        </Routes>
                    </Layout>
                </ReduxProvider>
            </DappProvider>
        </Router>
    );
};
