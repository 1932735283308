import React, { PropsWithChildren, useEffect } from 'react';
import {
    useGetAccount,
    useGetPendingTransactions,
} from '@multiversx/sdk-dapp/hooks';
import { EGLD_PRICE_UPDATE_PERIOD, VEGLD } from 'config';
import {
    setLiquidStakingSettings,
    setVegldBalance,
    setEgldPrice,
    setElrondStats,
    selectLiquidStaking,
    setUnstakingPacks,
    setWithdrawableAmount,
} from 'reducers';
import {
    useAppDispatch, useAppSelector,
} from 'store';
import {
    getEgldPriceFromApi,
    getElrondStatsFromApi,
    getTokenBalanceFromApi,
    elrondViewLiquidStakingSettings,
    elrondGetUnstakingPacks,
} from 'z/elrond';
import { convertWeiToEsdt } from 'z/utils';

export const ReduxProvider = ({ children }: PropsWithChildren) => {
    const { hasPendingTransactions } = useGetPendingTransactions();
    const { address } = useGetAccount();

    const dispatch = useAppDispatch();
    const liquidStaking = useAppSelector(selectLiquidStaking);
    
    const triggerGetEgldPrice = () => {
        (async () => {
            const _egldPrice = await getEgldPriceFromApi();
            // console.log('_egldPrice',_egldPrice);
            dispatch(setEgldPrice(_egldPrice));
        })();
        
        (async () => {
            const value = await getElrondStatsFromApi();
            // console.log('elrondStats', value);
            dispatch(setElrondStats(value));
        })();
    };

    useEffect(() => {
        triggerGetEgldPrice();
        const interval = setInterval(() => {
            triggerGetEgldPrice();
        }, EGLD_PRICE_UPDATE_PERIOD);
        return () => clearInterval(interval);
    }, []);
    
    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            const _value = await elrondViewLiquidStakingSettings();
            // console.log('elrondViewLiquidStakingSettings', _value);
            dispatch(setLiquidStakingSettings(_value));
        })();
    }, [hasPendingTransactions]);
    
    useEffect(() => {
        if (!address || hasPendingTransactions) return;

        (async () => {
            const value = await getTokenBalanceFromApi(address, VEGLD.identifier);
            dispatch(setVegldBalance(value ? value.balance : null));
        })();
    }, [address, hasPendingTransactions]);

    useEffect(() => {
        (async () => {
            if (address) {
                const _packs = await elrondGetUnstakingPacks(address, liquidStaking.liquidStakingSettings);
                const _withdrawableAmount = _packs.reduce(
                    (accumulator, currentValue) => accumulator + (currentValue.withdrawable ? convertWeiToEsdt(currentValue.amount).toNumber() : 0),
                    0
                );
                dispatch(setUnstakingPacks(_packs));
                dispatch(setWithdrawableAmount(_withdrawableAmount));
            } else {
                dispatch(setUnstakingPacks([]));
                dispatch(setWithdrawableAmount(0));
            }
            
        })();
    }, [address, liquidStaking.liquidStakingSettings]);
    
    return <>{children}</>;
};
