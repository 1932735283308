import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { LiquidStakingSettingsType, UnstakingPackType } from 'z/types';

export interface LiquidStakingState {
    liquidStakingSettings: LiquidStakingSettingsType | undefined,
    vegldBalance: string | null,
    unstakingPacks: UnstakingPackType[],
    withdrawableAmount: number,
}

const initialState: LiquidStakingState = {
    liquidStakingSettings: undefined,
    vegldBalance: null,
    unstakingPacks: [],
    withdrawableAmount: 0,
};

export const liquidStakingSlice = createSlice({
    name: 'liquidStaking',
    initialState,
    reducers: {
        setLiquidStakingSettings: (state, action: PayloadAction<LiquidStakingSettingsType | undefined>) => {
            state.liquidStakingSettings = action.payload;
        },
        setVegldBalance: (state, action: PayloadAction<string | null>) => {
            state.vegldBalance = action.payload;
        },
        setUnstakingPacks: (state, action: PayloadAction<UnstakingPackType[]>) => {
            state.unstakingPacks = action.payload;
        },
        setWithdrawableAmount: (state, action: PayloadAction<number>) => {
            state.withdrawableAmount = action.payload;
        },
    }
});

export const { setLiquidStakingSettings, setVegldBalance, setUnstakingPacks, setWithdrawableAmount } = liquidStakingSlice.actions;
export const selectLiquidStaking = (state: RootState) => state.liquidStaking;

export default liquidStakingSlice.reducer;