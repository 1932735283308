import { EnvironmentsEnum } from '@multiversx/sdk-dapp/types';

// Generate your own WalletConnect 2 ProjectId here: https://cloud.walletconnect.com/app
export const walletConnectV2ProjectId = '9291145b1abc47b6a444559a36f7b7b4';
export const ELROND_ACCESS_TIMEOUT = 10_000; // 10s

// mainnet
export const ELROND_ENVIRONMENT = EnvironmentsEnum.mainnet;

//
const ELROND_GATEWAY_ADDRESSES = {
    mainnet: "https://gateway.multiversx.com",
    devnet: "https://devnet-gateway.multiversx.com",
    testnet: "https://testnet-gateway.multiversx.com",
};
export const ELROND_GATEWAY_ADDRESS = ELROND_GATEWAY_ADDRESSES[ELROND_ENVIRONMENT];
const ELROND_API_ADDRESSES = {
    mainnet: "https://api.multiversx.com",
    devnet: "https://devnet-api.multiversx.com",
    testnet: "https://testnet-api.multiversx.com",
};
export const ELROND_API_ADDRESS = ELROND_API_ADDRESSES[ELROND_ENVIRONMENT];
const ELROND_EXPLORER_ADDRESSES = {
    mainnet: "https://explorer.multiversx.com",
    devnet: "https://devnet-explorer.multiversx.com",
    testnet: "https://testnet-explorer.multiversx.com",
};
export const ELROND_EXPLORER_ADDRESS = ELROND_EXPLORER_ADDRESSES[ELROND_ENVIRONMENT];

//
export const VEGLD = {
    identifier: 'VEGLD-2b9319',
    ticker: 'vEGLD',
    decimals: 18,
};
export const LIQUID_STAKING_SMART_CONTRACT_ADDRESS = 'erd1qqqqqqqqqqqqqpgqawus4zu5w2frmhh9rscjqnu9x6msfjya2d2sfw7tsn';

//
export const FEE_DENOMINATOR = 100;

export const EGLD_PRICE_UPDATE_PERIOD = 30_000; // 30s

// For User
export const STAKE_FUNCTION_NAME = 'userStake';
export const STAKE_GAS_LIMIT = 30_000_000;
export const UNSTAKE_FUNCTION_NAME = 'userUnstake';
export const UNSTAKE_GAS_LIMIT = 30_000_000;
export const WITHDRAW_FUNCTION_NAME = 'userWithdraw';
export const WITHDRAW_GAS_LIMIT = 30_000_000;

//
export const AUDIT_ARDA_LINK = 'https://arda.run/audits/vestax';
export const AUDIT_BHNETWORK_LINK = 'https://bhero.com/services/smart-contract-audit-service';

//
export const DEMI_TWITTER_LINK = 'https://www.twitter.com/DemiourgosH';
export const DEMI_YOUTUBE_LINK = 'https://www.youtube.com/c/CarpathianPictures';
export const DEMI_DISCORD_LINK = 'https://discord.gg/PNzHfBs7xN';
export const DEMI_TELEGRAM_LINK = 'https://t.me/DemiourgosCommunity';
export const DEMI_WEBSITE_LINK = 'https://demiourgos.holdings';
export const VESTA_WEBSITE_LINK = 'https://vestax.finance';
