//
export const ZERO_STRING = '0';
export const DELTA_AMOUNT = 0.001;

//
export const ERROR_INVALID_NUMBER = 'Invalid number';
export const ERROR_NOT_ENOUGH_BALANCE = 'Not enough balance';
export const ERROR_CONNECT_WALLET = 'Connect your wallet';
export const ERROR_TRANSACTION_ONGOING = 'A transaction is ongoing';
export const ERROR_SC_DATA_NOT_LOADED = 'Smart Contract data is not loaded';
export const ERROR_NO_FUNDS_FOR_WITHDRAWL = 'No funds available for withdrawl';
export const ERROR_NO_ENOUGH_FUNDS_IN_POOL = 'No enough funds in the pool';
